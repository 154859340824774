export const theme = {
  light: {
    white: '#FFFFFF',
    black: '#000000',
    blackOpacity: 'rgba(0, 0, 0, 0.9)',
    dark: '#1E1E1E',
    heading: '#323543',
    text: '#686F81',
    grey: '#666666',
    greyLight: '#ADADAD',
    greyHover: '#D3D3D3',
    accentRed: 'rgba(219, 47, 47, 1)',
    accentBlue: 'rgba(65, 45, 183, 1)',
    accentGrey: 'rgba(200, 200, 200, 0.5)',
    form: '#8D8D8D',
  },

  dark: {
    white: '#000000',
    black: '#FFFFFF',
    blackOpacity: 'rgba(255, 255, 255, 0.9)',
    dark: '#FFFFFF',
    heading: '#f0f0f0',
    text: '#f0f0f0',
    grey: '#f0f0f0',
    greyLight: '#ADADAD',
    greyHover: '#f0f0f0',
    accentRed: 'rgba(219, 47, 47, 1)',
    accentGrey: 'rgba(200, 200, 200, 0.5)',
    form: '#f0f0f0',
  },
};
